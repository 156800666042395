@charset "utf-8";

/* Fonts */
@font-face {
  font-family: 'Gotham Regular';
  src: url('./fonts/Copia de Gotham-Book.otf');
  font-weight: regular;
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('./fonts/Copia de Gotham-Bold.otf');
  font-weight: bold;
}
@font-face {
  font-family: 'Gotham Light';
  src: url('./fonts/Copia de Gotham-Light.otf');
  font-weight: light;
}
@font-face {
  font-family: 'Cocogoose Narrow Regular';
  src: url('./fonts/Cocogoose-Narrow-Regular-trial.ttf');
  font-weight: regular;
}
@font-face {
  font-family: 'Cocogoose Condensed Regular';
  src: url('./fonts/Cocogoose-Condensed-Regular-trial.ttf');
  font-weight: regular;
}
@font-face {
  font-family: 'Cocogoose Compressed Regular';
  src: url('./fonts/Cocogoose-Compressed-Regular-trial.ttf');
  font-weight: regular;
}
@font-face {
  font-family: 'Bernier';
  src: url('./fonts/BERNIERRegular-Regular.ttf');
}
/* css variables */
:root {
  --primaryColor: #ffd400;
  --darkColor: rgb(1, 2, 9);
  --lightColor: #f5f6fa;
}

/* Base styles */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

a {
  text-decoration: none;
  color: var(--lightColor);
  font-family: 'Gotham Regular', sans-serif;
  font-size: 1.5rem;

  transition: all 0.3s ease-in-out;
}

a:hover {
  color: var(--primaryColor);
}

/* Active link class */
.active {
  color: var(--primaryColor);
}
